import React, { useState } from "react";
import { format } from "date-fns";
import exportFromJSON from "export-from-json";

// firebase imports
import { db } from "../../firebase/config";
import {
  getDocs,
  collection,
  orderBy,
  query,
  where,
  documentId,
} from "firebase/firestore";

import { Button, Grid, Paper, capitalize } from "@mui/material";
import DateTimePicker from "../../components/FormUI/DateTimePicker";
import { Form, Formik } from "formik";
import Error from "../../components/error/Error";

import styles from "./Reports.module.css";

const UPL_COLLECTION = "unsecuredLoanApplications";
const ASSESSED_COLLECTION = "assessedUPL";

const Reports = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileName = "upl-data";
  const exportType = exportFromJSON.types.csv;

  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const getData = async (start, end) => {
    let q = "";
    let approvedApps = [];
    let apps = [];
    let dataApprovedAmounts = [];

    // ----------------------------------------------
    // get the upl applications
    try {
      const uplRef = collection(db, UPL_COLLECTION);
      q = query(
        uplRef,
        where("dateApplied", ">=", new Date(start)),
        where("dateApplied", "<", new Date(end)),
        orderBy("dateApplied", "desc"),
        orderBy("firstName", "asc")
      );
      // execute the query
      const res = await getDocs(q);

      apps = res.docs.map((doc) => ({
        id: doc.id,
        "Customer Name":
          doc.data().firstName +
          " " +
          doc.data().otherNames +
          " " +
          doc.data().lastName,
        "Date of Birth": doc.data().dateOfBirth
          ? format(new Date(doc.data().dateOfBirth), "yyyy-MM-dd")
          : null,

        // DOB: format(new Date(doc.data().dateOfBirth), "dd MMM yyyy"),
        // "Annual Salary": doc.data().annualSalary,
        Address: doc.data().residentialAddress1,
        // doc.data().residentialAddress1 + " " + doc.data().residentialAddress2,
        Assessor: doc.data().assessor?.displayName,
        Status: doc.data().status,
        "Loan Amount": `${USDollar.format(doc.data().loanAmount)}`,
        "Marital Status": doc.data().maritalStatus,
        Employer: doc.data().employer,
        Occupation: doc.data().occupation,
        "Annual Salary": doc.data().annualSalary,
        "Customer Status": doc.data().mflCustomerStatus,
        "Application Status": doc.data().status,
        "Date Applied": doc.data()?.dateApplied
          ? format(doc.data().dateApplied.toDate(), "yyyy-MM-dd")
          : null,
        "Date Assessed": doc.data()?.dateAssessed
          ? format(doc.data().dateAssessed.toDate(), "yyyy-MM-dd")
          : null,
        "Date Recommended": doc.data()?.dateRecommended
          ? format(doc.data().dateRecommended.toDate(), "yyyy-MM-dd")
          : null,
        "Date Approved": doc.data()?.dateApproved
          ? format(doc.data().dateApproved.toDate(), "yyyy-MM-dd")
          : null,
        "Date Declined": doc.data()?.dateDeclined
          ? format(doc.data().dateDeclined.toDate(), "yyyy-MM-dd")
          : null,
        "Repayment Mode": doc.data().extra?.repaymentMode,
        "Approved By": doc.data().approvedBy,
      }));

      // ####
      // console.log("res.docs", apps);

      // filter apps to only approved applications
      // approvedApps = apps.filter((app) => app["Status"] === "Approved");

      //extract the ids of the approved applications
      // approvedApps = approvedApps.map((app) => app.id);

      //set the data
      setData(apps);
    } catch (err) {
      setError("Error getting data: " + err.message);
    }

    // ----------------------------------------------
    // get the approved applications details from the assessed applications
    try {
      // const assessedUPLRef = collection(db, ASSESSED_COLLECTION);
      let qry = query(
        collection(db, ASSESSED_COLLECTION),
        where("status", "==", "Approved"),
        where("dateSaved", ">=", new Date(start)),
        where("dateSaved", "<", new Date(end)),
        orderBy("dateSaved", "desc")
      );

      // execute the query
      const results = await getDocs(qry);

      dataApprovedAmounts = results.docs.map((doc) => ({
        id: doc.id,
        "Approved Loan Amount": doc.data().loanAmount,
      }));
    } catch (err) {
      setError("Error getting data: " + err.message);
    }

    // ----------------------------------------------
    // set the approved loan amount in the apps array
    apps.forEach((app) => {
      dataApprovedAmounts.forEach((approvedApp) => {
        if (app.id === approvedApp.id) {
          // `${USDollar.format(approvedApp["Approved Loan Amount"])}`,
          // app["Approved Loan Amount"] = approvedApp["Approved Loan Amount"];
          app["Approved Loan Amount"] = `${USDollar.format(
            approvedApp["Approved Loan Amount"]
          )}`;
        }
      });
    });

    // remove the id from the apps object array
    let finalApps = apps.map((obj) => {
      const { id, ...rest } = obj;
      return rest;
    });

    // ----------------------------------------------
    // export to csv
    exportFromJSON({
      data: finalApps,
      fileName: fileName,
      exportType: exportType,
    });
  };

  const initialValues = {
    // set start as the begginning of the current month in the fomr YYYY-MM-DD
    start: format(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      "yyyy-MM-dd"
    ),
    end: format(
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
      "yyyy-MM-dd"
    ),
  };

  const today = format(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
    "yyyy-MM-dd"
  );

  const handleSubmit = (values) => {
    getData(values.start, values.end);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Paper
        elevation={0}
        variant="outlined"
        style={{
          marginBottom: "20px",
          padding: "1rem",
          border: "1px solid #002a48",
        }}
      >
        <h1>Data Download</h1>
        <section className={styles.main}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ field, form }) => (
              <Form>
                <Grid container spacing={2}>
                  {/* start date */}
                  <Grid item xs={12} sm={6}>
                    <DateTimePicker name="start" label="Start Date*" />
                  </Grid>

                  {/* end date */}
                  <Grid item xs={12} sm={6}>
                    <DateTimePicker name="end" label="End Date*" />
                  </Grid>
                </Grid>
                <br></br>
                {/* actions */}
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ textTransform: "capitalize" }}
                >
                  Get Report Data
                </Button>
              </Form>
            )}
          </Formik>
        </section>
      </Paper>

      {/* error display */}
      {error && <Error err={error} module={"Reports"} />}
    </div>
  );
};

export default Reports;
