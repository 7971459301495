import React, { useState } from 'react';

import { v4 as uuid } from 'uuid';
import { Grid } from '@mui/material';
import Error from '../../components/error/Error';

// components

const UsersForm = (props) => {
	const { users, roles, updateUserRole } = props;

	const [isDisabled, setIsDisabled] = useState(false);
	const [error, setError] = useState(null);

	const resetRoles = () => {
		//reset
		try {
			roles.map((role) => {
				if (document.getElementById(role.role)) {
					document.getElementById(role.role).checked = false;
				}
			});
		} catch (error) {
			setError('Error resetting: ' + error.message);
		}
	};

	const selectOnChange = (e) => {
		// reset the roles for new user has been selected
		resetRoles();

		if (document.getElementById('users').value === '') {
			return;
		}

		// use the value as the filter to get the roles from the users array
		const userRoles = users.filter((user) => user.email === e.target.value);

		try {
			if (userRoles[0].roles) {
				// set the role checkboxes to checked
				userRoles[0].roles.forEach((role) => {
					if (document.getElementById(role)) {
						document.getElementById(role).checked = true;
					}
				});
			}
		} catch (error) {
			setError('Error setting roles: ' + error.message);
		}
	};

	const roleOnChange = (e) => {};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (document.getElementById('users').value === '') {
			return;
		}

		let updatedRoles = [];
		let userEmail = document.getElementById('users').value;

		//itereate through the roles and get the checked ones
		roles.forEach((role) => {
			if (document.getElementById(role.role).checked) {
				updatedRoles.push(role.role);
			}
		});

		// find the user id in the users array using the email
		const selectedUser = users.find((user) => user.email === userEmail);
		setIsDisabled(true);
		updateUserRole(selectedUser.id, updatedRoles);
		setIsDisabled(false);
	};

	return (
		<div
			style={{
				background: '#fff',
				borderRadius: '5px',
				paddingTop: '1rem',
			}}
		>
			<h1>User Role Management</h1>

			<form onSubmit={handleSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} alignContent={'center'}>
						{/* users */}
						<div
							style={{
								marginBottom: '10px',
								border: '1px solid ',
								padding: '1rem',
								borderRadius: '3px',
								height: '210px',
								width: '90%',
								margin: '0 auto',
							}}
						>
							<select
								name='users'
								id='users'
								onChange={(e) => selectOnChange(e)}
								size='10'
								// width="250px"
								width='95% !important'
							>
								<option key={uuid()} value=''>
									-- Please choose a user --
								</option>
								{users.map((user) => (
									<option key={uuid()} value={user.email}>
										{user.email}
									</option>
								))}
							</select>
						</div>
					</Grid>

					<Grid item xs={12} sm={6}>
						{/* roles */}
						<div
							id='roles'
							style={{
								marginBottom: '0px',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-start',
								border: '1px solid #002a48',
								padding: '1rem',
								width: '90%',
								borderRadius: '3px',
								height: '210px',
								margin: '0 auto',
							}}
						>
							{roles.map((role) => (
								<div key={uuid()} style={{ paddingBottom: '10px' }}>
									<input
										type='checkbox'
										id={role.role}
										// name={role.role}
										name='role'
										value={role.role}
										onChange={(e) => roleOnChange(e)}
									/>
									<label htmlFor={role.role}>
										{/* capitalise the first letter of the role */}
										{role.role.charAt(0).toUpperCase() + role.role.slice(1)}
									</label>
									<br></br>
								</div>
							))}
						</div>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12}>
						{/* actions */}
						<div style={{ paddingBottom: '1rem', paddingTop: '1rem' }}>
							<button
								type='submit'
								style={{
									backgroundColor: '#002a48',
									color: '#fff',
									padding: '.75rem',
									borderRadius: '5px',
									width: '150px',
								}}
								disabled={isDisabled}
							>
								Save Roles
							</button>
						</div>
					</Grid>
				</Grid>
				{/* error display */}
				{error && <Error err={error} module={'Dashboard'} />}
			</form>
		</div>
	);
};

export default UsersForm;
