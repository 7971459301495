import React from "react";
import styles from "./Layout.module.css";

import DrawerAppBar from "../toolbar/Toolbar";
import Footer from "../../pages/footer/Footer";

const Layout = ({ children }) => {
  return (
    <div>
      <DrawerAppBar />
      <h1>TESTING ENVIRONMENT</h1>

      <div className={styles.container}>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
