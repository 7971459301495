import { Paper, Grid, Button } from '@mui/material';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import React, { useState, useEffect } from 'react';

import SubmitButton from './FormUI/SubmitButton';
import ActionButton from './FormUI/ActionButton';
import CheckboxWrapper from './FormUI/CheckboxWrapper';
import FileInput from './FormUI/FileInput';
import Highlight from './highlight/Highlight';
import ArrowCircleRightTwoToneIcon from '@mui/icons-material/ArrowCircleRightTwoTone';
import ArrowCircleLeftTwoToneIcon from '@mui/icons-material/ArrowCircleLeftTwoTone';

import styles from './Refs.module.css';

const MAX_COUNT = 6;
let FILE_MSG =
	'Please do ensure that you provide all the mandatory files which have been marked with an *.' +
	'\n';
FILE_MSG =
	FILE_MSG +
	' Failure to do so will result in your application being declined.' +
	'\n';
FILE_MSG = FILE_MSG + 'Files accepted are images and pdf.' + '\n';
FILE_MSG = FILE_MSG + 'The maximum size for a file is 10MB';

let OTHER_STMT_MSG =
	'If you have stated that you have other loan(s), hire purchase(s), credit card(s), additonal income, you must provide the most recent statements covering the past 2 months. ';
OTHER_STMT_MSG =
	OTHER_STMT_MSG +
	'Also use this for providing supporting documents such as quotes. Failure to do so will result in your application being declined.';

const LoanFiles = ({ data, next, prev, editMode, isLoading }) => {
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [fileLimit, setFileLimit] = useState(false);

	const validationSchema = Yup.object().shape({
		// signature: Yup.mixed().required("Signature file is required!"),
		signature: Yup.mixed()
			.required('Your signature file is required!')
			.test('file', 'Your signature file is required!', (value) => {
				if (value.length > 0) {
					return true;
				}
				return false;
			}),
		photoIDs: Yup.mixed().test(
			'file',
			'Two (2) valid photo IDs are required!',
			(value) => {
				if (value.length > 0) {
					return true;
				}
				return false;
			}
		),
		employmentConfirmation: Yup.mixed().test(
			'file',
			'Your employment confirmation letter file is required!',
			(value) => {
				if (value.length > 0) {
					return true;
				}
				return false;
			}
		),
		tinLetterCard: Yup.mixed().test(
			'file',
			'Your TIN letter or card file is required!',
			(value) => {
				if (value.length > 0) {
					return true;
				}
				return false;
			}
		),
		payslips: Yup.mixed().test(
			'file',
			'Two latest payslips are required!',
			(value) => {
				if (value.length > 0) {
					return true;
				}
				return false;
			}
		),
		bankStatement: Yup.mixed().test(
			'file',
			'Your bank statement for the past 3 recent months is required!',
			(value) => {
				if (value.length > 0) {
					return true;
				}
				return false;
			}
		),
		creditConsent: Yup.boolean()
			.required('You must accept the Credit Information Consent!')
			.oneOf([true], 'You must accept the Credit Information Consent!'),
		infoDeclaration: Yup.boolean()
			.required('You must accept the Information Declaration!')
			.oneOf([true], 'You must accept the Information Declaration!'),
	});

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [data]);

	const handleSubmit = (values) => {
		console.log('values', values);
		next(values);
	};

	return (
		<div className='flex-center'>
			<Paper elevation={10} className='container'>
				<div className='container-decorator'></div>
				<Formik
					initialValues={data}
					onSubmit={handleSubmit}
					validationSchema={validationSchema}
				>
					{({ values, errors, touched, props }) => (
						<Form>
							<h2 className='title-decorator'>Loan Files</h2>

							<Paper elevation={0} variant='outlined' className='paper'>
								{/* required files */}
								<Grid container padding={2} rowGap={2}>
									{/* notice about files */}
									<Grid item xs={12}>
										<Highlight text={FILE_MSG} />
									</Grid>

									{/* signature */}
									<Grid item xs={12} sm={6}>
										<div style={{ padding: '1em' }}>
											<FileInput
												label='Provide your signature file*'
												maxFiles='1'
												name='signature'
												autoFocus
											/>
											{/* <small>Max number of files: 1</small> */}
										</div>
									</Grid>

									{/* photo IDs */}
									<Grid item xs={12} sm={6}>
										<div style={{ padding: '1em' }}>
											<FileInput
												label='Provide two(2) valid photo IDs*'
												maxFiles={2}
												name='photoIDs'
											/>
											{/* <small>Max number of files: 2</small> */}
										</div>
									</Grid>

									{/* employment confirmation */}
									<Grid item xs={12} sm={6}>
										<div style={{ padding: '1em' }}>
											<FileInput
												label='Provide your employment confirmation letter*'
												maxFiles={1}
												name='employmentConfirmation'
											/>
											{/* <small>Max number of files: 1</small> */}
										</div>
									</Grid>

									{/* TIN */}
									<Grid item xs={12} sm={6}>
										<div style={{ padding: '1em' }}>
											<FileInput
												label='Provide your TIN card or TIN letter*'
												maxFiles={1}
												name='tinLetterCard'
												// accept="accept: {'image/*': [],}"
											/>
											{/* <small>Max number of files: 1</small> */}
										</div>
									</Grid>

									{/* payslips */}
									<Grid item xs={12} sm={6}>
										<div style={{ padding: '1em' }}>
											<FileInput
												label='Provide your two (2) most recent latest payslips*'
												maxFiles={2}
												name='payslips'
											/>
											{/* <small>Max number of files: 2</small> */}
										</div>
									</Grid>

									{/* bank statement */}
									<Grid item xs={12} sm={6}>
										<div style={{ padding: '1em' }}>
											<FileInput
												label='Your bank statement for the past 3 recent months*'
												maxFiles={6}
												name='bankStatement'
											/>
											{/* <small>Max number of files: 6</small> */}
										</div>
									</Grid>

									{/* notice about other statements for loans, hire purchase, etc */}
									<Grid item xs={12}>
										<Highlight text={OTHER_STMT_MSG} />
									</Grid>

									{/* other statements */}
									<Grid item xs={12} sm={6}>
										<div style={{ padding: '1em' }}>
											<FileInput
												label='Provide your most recent (loans, hire purchase, additional incomce, etc) statements or supporting documents for your application'
												maxFiles={8}
												name='otherStatements'
											/>
											{/* <small>Max number of files: 7</small> */}
										</div>
									</Grid>
								</Grid>

								{/* horizontal rule */}
								<hr></hr>

								{/* terms  ======================================================================== */}
								<Grid container flexDirection='column' alignItems='flex-start'>
									<h3 className='title-left'>Debt</h3>

									{/* bankrupt */}
									<Grid item xs={12}>
										<CheckboxWrapper
											name='bankruptcy'
											legend='Bankruptcy'
											label='Have you ever been declared bankrupt or had bankruptcy proceedings against you?'
										/>
									</Grid>

									{/* debt recovery */}
									<Grid item xs={12}>
										<CheckboxWrapper
											name='debtRecovery'
											legend='Legal Proceeedings'
											label='Have you ever had legal proceedings brought up against you for the recovery of debt?'
										/>
									</Grid>

									{/* mandatory section  ========================================================================  */}
									{/* credit check consent */}
									<Grid item xs={12}>
										<h3 className='title-left'>
											Credit information and Consent
										</h3>
										<CheckboxWrapper
											name='creditConsent'
											legend='Credit Information Consent*'
											value='false'
											label='1. I/We hereby consent to the release of credit information held
              by Merchant Finance Pte Limited to any Credit Reporting Agency and
              also authorize Merchant Finance Pte Limited to enquire with and to
              obtain credit information relating to me/us from any Credit
              Reporting Agency in relation to my credit facility. This authority
              is given by me/us and obtained by Merchant Finance Pte Limited
              pursuant to Regulation 9(6) of the Fair Reporting Regulation 2016.'
										/>
									</Grid>

									{/* info declaration */}
									<Grid item xs={12}>
										<CheckboxWrapper
											name='infoDeclaration'
											legend='Information Declaration*'
											value='false'
											label='2. I/We hereby declare that the information in this application
                        for finance is true and accurate and Merchant Finance Pte Limited
                        is authorized to carry out any further checks.'
										/>
									</Grid>
								</Grid>
							</Paper>

							{/* action buttons ======================================================================== */}
							<Grid container>
								<Grid
									item
									xs={12}
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										width: '100%',
									}}
								>
									{/* back */}
									<ActionButton
										onClick={() => {
											prev(values);
										}}
										disabled={isLoading}
									>
										<ArrowCircleLeftTwoToneIcon
											sx={{
												fontSize: '1.75rem !important',
											}}
										/>
										&nbsp;Back
									</ActionButton>
									{/* next */}
									<SubmitButton disabled={editMode}>
										Next&nbsp;
										<ArrowCircleRightTwoToneIcon
											sx={{
												fontSize: '1.75rem !important',
											}}
										/>
									</SubmitButton>
								</Grid>
							</Grid>
							{/* <ErrorMessage name="signature" className={styles.error} />
                <br></br>
                <ErrorMessage
                  name="creditConsent"
                  // className={styles.error}
                  sytles={{ color: "red" }}
                />
                <br></br>
                <ErrorMessage
                  name="infoDeclaration"
                  sytles={{ color: "red" }}
                /> */}
						</Form>
					)}
				</Formik>
			</Paper>
			{/* </section> */}
		</div>
	);
};

export default LoanFiles;
