import React, { useEffect, useState } from 'react';
import {
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
} from '@mui/material';
import { format } from 'date-fns';
import { Link, useNavigate } from 'react-router-dom';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

const ListApplications = (props) => {
	const { data, page, pageSize, totalCount, handleChangePage } = props;

	const [isNoData, setIsNoData] = useState(false);
	const navigate = useNavigate();
	const noDataMsg =
		'Congratulations 👏🎊🎉 Great Work 👍!! Applications have been cleared for this category.';

	useEffect(() => {
		setIsNoData(false);
		if (data && data.length === 0) {
			setIsNoData(true);
		}
	}, [data]);

	return (
		<div>
			{/* <h2>Search</h2> */}
			<TableContainer component={Paper}>
				<Table aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell align='center' style={{ fontWeight: 'bold' }}>
								Name
							</TableCell>
							<TableCell align='center' style={{ fontWeight: 'bold' }}>
								Date Applied
							</TableCell>
							<TableCell align='center' style={{ fontWeight: 'bold' }}>
								Status
							</TableCell>
							<TableCell align='center' style={{ fontWeight: 'bold' }}>
								Loan Amount
							</TableCell>
							<TableCell align='center' style={{ fontWeight: 'bold' }}>
								Assessor
							</TableCell>
							<TableCell align='center' style={{ fontWeight: 'bold' }}>
								Action
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data &&
							data.map((app, i) => (
								<TableRow
									key={i}
									// sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
								>
									{/* applicant name */}
									<TableCell>
										{app.docStatus && (
											<span>
												<Tooltip title='Closing documents produced' arrow>
													<DescriptionOutlinedIcon
														fontSize='medium'
														color='success'
														style={{ verticalAlign: 'bottom' }}
													/>
												</Tooltip>
											</span>
										)}
										<Link to={`/upl-application/${app.id}`} target='_blank'>
											{app.firstName} {app.otherNames} {app.lastName}
										</Link>
									</TableCell>

									{/* date applied */}
									<TableCell align='center'>
										{app.appliedAt
											? format(
													new Date(app.appliedAt.seconds * 1000),
													'EEE dd MMM yyyy hh:mm a'
											  )
											: null}
									</TableCell>
									<TableCell align='center'>{app.status}</TableCell>

									{/* loan amount */}
									<TableCell align='center'>
										${new Intl.NumberFormat().format(app.loanAmount)}
									</TableCell>

									{/* assessor */}
									<TableCell align='center'>
										{app.assessor &&
											app.assessor.displayName.substring(
												0,
												app.assessor.displayName.indexOf(' ')
											)}
										{app.assessor &&
											app.assessor.displayName.indexOf(' ') < 0 &&
											app.assessor.displayName}
									</TableCell>
									{/* action */}
									<TableCell align='center'>
										<Button
											variant='contained'
											// color="secondary"
											sx={{
												minWidth: '100px',
												textTransform: 'capitalize',
												backgroundColor: '#002a48',
												borderRadius: '25px',
												size: 'small',
											}}
											onClick={() => navigate(`/assess/${app.id}`)}
										>
											Assess
											{/* <Link to={`/assess/${app.id}`} target="_blank">
                        // Assess //{" "}
                      </Link> */}
										</Button>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
				<TablePagination
					component='div'
					rowsPerPageOptions={[pageSize]}
					rowsPerPage={pageSize}
					count={totalCount}
					page={page}
					onPageChange={handleChangePage}
				/>
			</TableContainer>

			{/* no data message */}
			{isNoData && (
				<p
					style={{
						border: '1px solid #c3d500',
						borderRadius: '5px',
						padding: '1rem',
						background: '#c3d500',
						color: '#fff',
					}}
				>
					<strong>{noDataMsg}</strong>
				</p>
			)}
		</div>
	);
};

export default ListApplications;
