import React, { useState } from 'react';
import Textfield from '../components/FormUI/Textfield';
import Selectfield from '../components/FormUI/Selectfield';
import DateTimePicker from '../components/FormUI/DateTimePicker';
import RadioInput from '../components/FormUI/RadioInput';

import SubmitButton from './FormUI/SubmitButton';

import { Button, Grid, InputAdornment, Paper } from '@mui/material';
import ArrowCircleRightTwoToneIcon from '@mui/icons-material/ArrowCircleRightTwoTone';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import styles from './Refs.module.css';

const AppDetails = (props) => {
	const [formValues, setFormValues] = useState(null);

	const bankOptions = {
		'': 'Select the bank for funds deposit',
		bsp: 'BSP',
		westpac: 'Westpac',
		anz: 'ANZ',
		bred: 'Bred',
		hfc: 'HFC',
		baroda: 'Baroda',
	};

	const payFrequencyOptions = {
		'': 'Select your pay frequency',
		weekly: 'Weekly',
		fortnightly: 'Fortnightly',
		bimonthly: 'Bi-Monthly',
		monthly: 'Monthly',
	};

	const maritialStatusOptions = {
		'': 'Select your martial status',
		single: 'Single',
		married: 'Married',
		divorced: 'Divorced',
		widowed: 'Widowed',
		defacto: 'Defacto',
	};

	const genderOptions = {
		'': 'Select your gender',
		female: 'Female',
		male: 'Male',
	};

	const mflCustomerOptions = [
		{ key: 'New', value: 'New' },
		{ key: 'Existing', value: 'Existing' },
	];

	const validationSchema = Yup.object().shape({
		mflCustomerStatus: Yup.string().required(
			'Your Merchant Finance customer status is required!'
		),
		firstName: Yup.string()
			.min(3, 'First name should be at least 3 characters!')
			.max(40, 'First name is too long!')
			.required('First name is required!'),
		gender: Yup.string().required('Your gender is required!'),
		postalAddress: Yup.string()
			.min(5, 'Postal address should be at least 5 characters!')
			.max(50, 'Postal address is too long!')
			.required('Postal address is required!'),
		residentialAddress1: Yup.string()
			.min(5, 'Residential address should be at least 5 characters!')
			.max(50, 'Residential address is too long!')
			.required('Residential address is required!'),
		maritalStatus: Yup.string().required('Your marital status is required!'),
		durationOfStay1: Yup.string()
			.min(5, 'Duration of stay should be at least 5 characters!')
			.max(20, 'Duration of stay is too long!')
			.required('Duration of stay is required!'),
		email: Yup.string()
			.email('Email is invalid')
			.required('Email is required!'),
		mobile: Yup.string()
			.min(7, 'Phone/Mobile # should be at least 7 characters!')
			.max(7, 'Phone/Mobile # is too long!')
			.required('Phone/Mobile # is required!'),
		dateOfBirth: Yup.date()
			.required('Date of birth is required!')
			.max(new Date(), "You can't be born in the future!"),
		fnpf: Yup.string()
			.min(5, 'FNPF number must be at least 5 characters!')
			.max(15, 'FNPF number is too long, must be no more than 15 characters!')
			.required('FNPF number is required!'),
		tin: Yup.string()
			.min(8, 'Tax Identification Number is too short, 9 digits are required!')
			.max(
				10,
				'Tax Identification Number is too long, no more than 9 digits are required!'
			)
			.required('Tax Identification Number is required!'),
		occupation: Yup.string()
			.min(5, 'Occupation is should be at least 5 characters!')
			.max(30, 'Occupation is too long!')
			.required('Occupation is required!'),
		payFrequency: Yup.string().required('Pay frequency is required!'),
		annualSalary: Yup.string()
			.min(4, 'Annual salary should be at least 4 digits!')
			.max(7, 'Annual salary is too long!')
			.required('Annual salary is required!'),
		employer: Yup.string()
			.min(5, 'Employer name should be at least 5 characters!')
			.max(40, 'Employer name is too long!')
			.required('Employer name is required!'),
		employerAddress: Yup.string()
			.min(5, 'Employer address should be at least 5 characters!')
			.max(40, 'Employer address is too long!')
			.required('Employer address is required!'),
		durationOfEmployment1: Yup.string()
			.min(5, 'Duration of employment should be at least 5 characters!')
			.max(20, 'Duration of employment is too long!')
			.required('Duration of employment is required!'),
		branch: Yup.string()
			.min(2, 'Branch name should be at least 2 characters!')
			.max(40, 'Branch name is too long!')
			.required('Branch name is required!'),
		accountName: Yup.string()
			.min(5, 'Account name should be at least 5 characters!')
			.max(80, 'Account name is too long!')
			.required('Account name is required!'),
		accountNumber: Yup.string()
			.min(5, 'Account number should be at least 5 characters!')
			.max(40, 'Account number is too long!')
			.required('Account number is required!'),
		bank: Yup.string().required('Your bank is required!'),
	});

	const handleSubmit = (values) => {
		props.next(values);
	};

	return (
		<div id='appDetails' className='flex-center'>
			<Paper elevation={10} className='container'>
				<div className='container-decorator'></div>
				<Formik
					initialValues={formValues || props.data}
					onSubmit={handleSubmit}
					validationSchema={validationSchema}
					enableReinitialize
				>
					{/* {(formProps) => ( */}
					{({ field, form }) => (
						<div>
							<Form>
								<Paper elevation={0} variant='outlined' className='paper'>
									{/* Personal details =================================================== */}
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<h2 className='title-decorator title-left'>About You</h2>
										</Grid>

										<Grid item xs={12}>
											<RadioInput
												name='mflCustomerStatus'
												label='Are you a new or existing Merchant Finance customer? '
												options={mflCustomerOptions}
												autoFocus
											/>
										</Grid>

										{/* first name */}
										<Grid item xs={12} sm={6}>
											<Textfield label='First Name*' name='firstName' />
										</Grid>

										{/* other names */}
										<Grid item xs={12} sm={6}>
											<Textfield label='Other Names' name='otherNames' />
										</Grid>

										{/* last name */}
										<Grid item xs={12} sm={6}>
											<Textfield label='Last Name' name='lastName' />
										</Grid>

										{/* gender */}
										<Grid item xs={12} sm={6}>
											<Selectfield
												label='Gender*'
												name='gender'
												options={genderOptions}
											/>
										</Grid>

										{/* postal address */}
										<Grid item xs={12} sm={6}>
											<Textfield label='Postal Address*' name='postalAddress' />
										</Grid>

										{/* residential address */}
										<Grid item xs={12} sm={6}>
											<Textfield
												label='Residential Address*'
												name='residentialAddress1'
											/>
										</Grid>

										{/* duration of stay */}
										<Grid item xs={12} sm={6}>
											<Textfield
												label='Duration of Stay*'
												name='durationOfStay1'
												helperText='How long have you been living at this address?'
											/>
										</Grid>

										{/* previous residential address */}
										<Grid item xs={12} sm={6}>
											<Textfield
												label='Previous Residential Address'
												name='residentialAddress2'
											/>
										</Grid>

										{/* duration of stay */}
										<Grid item xs={12} sm={6}>
											<Textfield
												label='Duration of Stay'
												name='durationOfStay2'
												helperText='How long have you been living at this address?'
											/>
										</Grid>

										{/* personal email */}
										<Grid item xs={12} sm={6}>
											<Textfield label='Personal Email*' name='email' />
										</Grid>

										{/* mobile */}
										<Grid item xs={12} sm={6}>
											<Textfield
												type='number'
												label='Mobile/Phone #*'
												name='mobile'
											/>
										</Grid>

										{/* marital status */}
										<Grid item xs={12} sm={6}>
											<Selectfield
												label='Marital Status*'
												name='maritalStatus'
												options={maritialStatusOptions}
											/>
										</Grid>

										{/* dependants */}
										<Grid item xs={12} sm={6}>
											<Textfield
												type='number'
												label='Number of Dependants'
												name='dependants'
											/>
										</Grid>

										{/* date of birth */}
										<Grid item xs={12} sm={6}>
											<DateTimePicker
												name='dateOfBirth'
												label='Date of Birth*'
											/>
										</Grid>

										<Grid item xs={12} sm={6}></Grid>
									</Grid>
								</Paper>

								{/* Employment details  =================================================== */}
								<Paper elevation={0} variant='outlined' className='paper'>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<h2 className='title-decorator title-left'>
												Employment Details
											</h2>
										</Grid>

										{/* FNPF */}
										<Grid item xs={12} sm={6}>
											<Textfield label='FNPF Number*' name='fnpf' />
										</Grid>

										{/* TIN */}
										<Grid item xs={12} sm={6}>
											<Textfield
												label='Tax Identification Number (TIN)*'
												type='number'
												name='tin'
											/>
										</Grid>

										{/* Occupation */}
										<Grid item xs={12} sm={6}>
											<Textfield label='Occupation*' name='occupation' />
										</Grid>

										{/* annual salary */}
										<Grid item xs={12} sm={6}>
											<Textfield
												label='Annual Salary*'
												type='number'
												name='annualSalary'
												InputProps={{
													startAdornment: (
														<InputAdornment position='start'>$</InputAdornment>
													),
													maxLength: 5,
												}}
											/>
										</Grid>

										{/* pay frequency */}
										<Grid item xs={12} sm={6}>
											<Selectfield
												label='Pay Frequency*'
												name='payFrequency'
												options={payFrequencyOptions}
											/>
										</Grid>

										{/* current employer */}
										<Grid item xs={12} sm={6}>
											<Textfield label='Current Employer*' name='employer' />
										</Grid>

										{/* address */}
										<Grid item xs={12} sm={6}>
											<Textfield label='Address*' name='employerAddress' />
										</Grid>

										{/* duration of employment */}
										<Grid item xs={12} sm={6}>
											<Textfield
												label='Duration of Employment*'
												name='durationOfEmployment1'
												helperText='How long have you been working for this employer?'
											/>
										</Grid>

										{/* previous employer */}
										<Grid item xs={12} sm={6}>
											<Textfield
												label='Previous Employer'
												name='previousEmployer'
											/>
										</Grid>

										{/* duration of employment2 */}
										<Grid item xs={12} sm={6}>
											<Textfield
												label='Duration of Employment'
												name='durationOfEmployment2'
											/>
										</Grid>

										{/* additional income */}
										<Grid item xs={12} sm={6}>
											<Textfield
												label='Monthly Additional Income'
												type='number'
												name='additionalIncome'
												InputProps={{
													startAdornment: (
														<InputAdornment position='start'>$</InputAdornment>
													),
													maxLength: 5,
												}}
											/>
										</Grid>
									</Grid>
								</Paper>

								{/* Bank details  =================================================== */}
								<Paper elevation={0} variant='outlined' className='paper'>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<h2 className='title-decorator title-left'>
												Banking Details
											</h2>
										</Grid>

										<Grid item xs={12} sm={6}>
											<Selectfield
												label='Bank*'
												name='bank'
												options={bankOptions}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Textfield label='Branch Name*' name='branch' />
										</Grid>
										<Grid item xs={12} sm={6}>
											<Textfield label='Account Name*' name='accountName' />
										</Grid>
										<Grid item xs={12} sm={6}>
											<Textfield label='Account Number*' name='accountNumber' />
										</Grid>
										{/* <Grid item xs={12}>
                      {/* <UploadWrapper /> */}
										{/* <button
                      type="button"
                      onClick={() => console.log("FV", formValues)}
                    >
                      Show
                    </button>{" "} */}
									</Grid>
								</Paper>

								{/* Buttons */}
								<Grid container>
									<Grid
										item
										xs={12}
										style={{
											display: 'flex',
											justifyContent: 'flex-end',
										}}
									>
										{/* <SubmitButton>
                      <div
                        style={{
                          disply: "flex",
                          flexDirection: "row",
                          gap: ".5rem",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div>Next</div>
                      </div>
                    </SubmitButton> */}

										<Button
											variant='contained'
											sx={{
												textTransform: 'capitalize',
												size: 'large',
												// backgroundColor: "#DBA11C",
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center',
												gap: '.75rem',
											}}
											type='submit'
										>
											Next
											<ArrowCircleRightTwoToneIcon
												sx={{
													fontSize: '1.75rem !important',
													// color: '#0d2a48',
												}}
											/>
										</Button>
									</Grid>
								</Grid>
							</Form>
						</div>
					)}
				</Formik>
			</Paper>
		</div>
	);
};

export default AppDetails;
