import { Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useAuthContext } from '../../hooks/useAuthContext';

import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
} from '@mui/material';
import { DialogTitle } from '@mui/material';
// import RadioInput from "../FormUI/RadioInput";
// import CheckboxWrapper from "../FormUI/CheckboxWrapper";
import { useNavigate, useParams } from 'react-router-dom';
import Error from '../error/Error';
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// firebase imports
import { db } from '../../firebase/config';
import {
	doc,
	updateDoc,
	getDoc,
	setDoc,
	serverTimestamp,
} from 'firebase/firestore';

import useGetDoc from '../../hooks/useGetDoc';

import Decline from './Decline';
import Approve from './Approve';
import Recommendation from './Recommendation';
import Recommend from './Recommend';

const RECOMMEND_NTFCTN =
	'The application has been recommended, assessment saved, and the client notified via email!';
const DECLINE_NTFCTN =
	'The application has been declined, assessment saved, and the client notified via email!';

const Actions = (props) => {
	const {
		sendMsg,
		isSending,
		loanAmount,
		firstName,
		lastName,
		otherNames,
		dateApplied,
		appID,
		status,
		result,
		loanDeets,
		finalExpenses,
		finalRates,
		appStatus,
		setAppStatus,
		surcharge,
		roles,
		isEscalatedTo,
		annualSalary,
		additionalIncome,
		// setHasAssessor,
		// hasAssessor,
	} = props;
	const { user } = useAuthContext();
	const navigate = useNavigate();

	const [open, setOpen] = useState(false);
	const [openRecommend, setOpenRecommend] = useState(false);
	const [openApprove, setOpenApprove] = useState(false);
	const [error, setError] = useState(null);
	const [recommendation, setRecommendation] = useState(null);
	const [isRecommended, setIsRecommended] = useState(false);
	const [isAssessing, setIsAssessing] = useState(false);
	const [isAssessor, setIsAssessor] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isApproved, setIsApproved] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isApprover, setIsApprover] = useState(false);
	const [isEscalated, setIsEscalated] = useState(false);
	const [isEscalator, setIsEscalator] = useState(false);
	const [isCompliance, setIsCompliance] = useState(false);
	const [hasAssessor, setHasAssessor] = useState(false);

	const { id } = useParams();

	// get the document from the collection based in their id
	// const { data: assessmentData } = useGetDoc("unsecuredLoanApplications", id);
	// const { data: roles } = useGetDoc("users", user.uid);

	const portalLink = `https://loans.mfl.com.fj`;
	const RECOMMEND_MSG =
		'Bula ' +
		firstName +
		',<br /><br />' +
		'Thank you for sending in all the requirements for your unsecured loan application.<br />' +
		'You will get a decision via email by the end of the next business day i.e. 24 hours<br />' +
		'You can also check the progress of your loan application on our <a href=' +
		portalLink +
		'>Loans Portal</a><br /><br />' +
		'Once again, thank you for giving us the opportunity to be your financial services provider of choice.';

	const test = dateApplied.toDate();

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	// START OF APPROVE ===================================================

	const handleCloseApprove = () => {
		setOpenApprove(false);
	};

	// update Finalise Approve status
	const updateFinaliseApproveStatus = async (
		finaliseComments,
		isEscalated = false
	) => {
		try {
			// set the document reference
			const docRef = doc(db, 'unsecuredLoanApplications', id);

			// get the document
			let hocEscalateTo = null;
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				console.log('Document data==>:', docSnap.data().hocEscalateTo);
				hocEscalateTo = docSnap.data().hocEscalateTo;
			}

			if (hocEscalateTo) {
				//   // was escalated by HOC to CEO
				const res = await updateDoc(docRef, {
					status: 'Approved',
					escalatedToFinalApprove_uid: user.uid,
					escalatedToFinalApprove_displayName: user.displayName,
					approvedBy: user.displayName,
					dateApproved: serverTimestamp(),
					escalatedToFinalComments: finaliseComments,
					approvedBy: user.displayName,
				});
			} else if (isEscalated) {
				//   // escalatedTo already
				const res = await updateDoc(docRef, {
					status: 'Approved',
					escalatedToApprove_uid: user.uid,
					escalatedToApprove_displayName: user.displayName,
					approvedBy: user.displayName,
					dateApproved: serverTimestamp(),
					escalatedToComments: finaliseComments,
					approvedBy: user.displayName,
				});
			} else {
				const res = await updateDoc(docRef, {
					status: 'Approved',
					finaliseApprove_uid: user.uid,
					finaliseApprove_displayName: user.displayName,
					dateApproved: serverTimestamp(),
					finaliseComments: finaliseComments,
					approvedBy: user.displayName,
				});
			}

			setAppStatus('Approved');
			setIsApproved(true);

			notifyUser(
				'The application has been Approved, the assessment saved, and the relevant parties notified via email! '
			);
		} catch (error) {
			setError(error.code + ': ' + error.message);
		}
	};

	// update Finalise Decline status
	const updateFinaliseDeclineStatus = async (
		finaliseComments,
		declineEmailMsg
	) => {
		try {
			const docRef = doc(db, 'unsecuredLoanApplications', id);
			await updateDoc(docRef, {
				finaliseDecline_uid: user.uid,
				declinedBy: user.displayName,
				dateFinaliseDeclined: serverTimestamp(),
				finaliseComments: finaliseComments,
				finaliseDeclineEmailMsg: declineEmailMsg,
				status: 'Declined',
			});
			setAppStatus('Declined');
			// setIsFinaliseDecline(true);

			notifyUser(
				'The application has been Declined, the assessment saved, and the relevant parties notified via email! '
			);
		} catch (error) {
			setError(error.message);
		}
	};
	// update Finalise Escalate status
	const updateFinaliseEscalateStatus = async (
		finaliseComments,
		escalatedTo
	) => {
		try {
			const docRef = doc(db, 'unsecuredLoanApplications', id);

			// get the document
			let escalated = null;
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				console.log('Document data:', docSnap.data());
				escalated = docSnap.data().escalatedTo;
			}

			// hocComments, hocEscalateTo
			if (escalated) {
				// escalating to CEO

				await updateDoc(docRef, {
					finaliseHOCEscalate_uid: user.uid,
					finaliseHOCEscalate_email: user.email,
					dateHOCEscalated: serverTimestamp(),
					hocComments: finaliseComments,
					status: 'Escalated',
					hocEscalateTo: escalatedTo,
				});
				setAppStatus('Escalated');
				setIsEscalated(true);
			} else {
				// escalating for the first time
				// ####
				console.log('1st escalate', finaliseComments);

				await updateDoc(docRef, {
					finaliseEscalate_uid: user.uid,
					finaliseEscalate_email: user.email,
					dateFinaliseEscalated: serverTimestamp(),
					finaliseComments: finaliseComments,
					status: 'Escalated',
					escalatedTo: escalatedTo,
				});
			}
			setAppStatus('Escalated');
			setIsEscalated(true);

			notifyUser(
				'The application has been Escalted, the assessment saved, and the relevant parties notified via email! '
			);
		} catch (error) {
			setError(error.message);
		}
	};

	const handleOpenApprove = () => {
		setOpenApprove(true);
	};

	// END OF APPROVE ===================================================

	// START OF RECOMMEND ===================================================

	const handleOpenRecommend = () => {
		recommendMsg();
		setOpenRecommend(true);
	};

	const handleCloseRecommend = () => {
		setOpenRecommend(false);
	};

	const handleRecommendApplication = () => {
		// send the email to the customer
		sendMsg(RECOMMEND_MSG, null, 'recommend');

		// save the assessment
		saveAssessment();

		// update the application status and date
		updateRecommendStatus();

		// close the modal
		handleCloseRecommend();
	};

	const recommendMsg = () => {
		let msg = '';
		msg = 'Loan Amount: $' + loanDeets.loanAmount + '\n';
		msg = msg + 'NAF: $' + loanDeets.totalAmountFinanced + '\n';
		msg = msg + 'DTI: ' + result.dti + '%\n';
		msg = msg + 'Loan term: ' + loanDeets.loanTerm + ' months\n';
		msg = msg + 'Total Salary Deduction: ' + '%\n';
		msg = msg + 'Net Weekly Surplus: $' + result.netWeeklySurplus + '\n';
		msg = msg + 'Recommended for approval';

		// console.log(msg);
		// return msg;
		setRecommendation(msg);
	};

	const updateRecommendStatus = async () => {
		try {
			const docRef = doc(db, 'unsecuredLoanApplications', id);
			const res = await updateDoc(docRef, {
				assessor: {
					uid: user.uid,
					displayName: user.displayName,
				},
				dateRecommended: serverTimestamp(),
				recommend_uid: user.uid,
				recommendation: recommendation,
				status: 'Recommended',
			});

			setAppStatus('Recommended');
			setIsRecommended(true);
			setIsAssessing(false);
			//notify the user
			notifyUser(RECOMMEND_NTFCTN);
		} catch (error) {
			setError(error.message);
			// console.log("Error Occured: ", error.message);
		}
	};

	//  END OF RECOMMEND ===================================================

	const notifyUser = (notification) => {
		toast.info(notification, {
			position: toast.POSITION.BOTTOM_LEFT,
		});
	};

	// save the recommended assessment
	const saveAssessment = async (decision = null) => {
		console.log('saving assessment...', status);

		try {
			const user_res = await setDoc(doc(db, 'assessedUPL', id), {
				...loanDeets,
				...result,
				...finalExpenses,
				...finalRates,
				surchargeRate: surcharge.surchargeRate,
				surcharge: surcharge.surcharge,
				uid: user.uid,
				displayName: user.displayName,
				dateSaved: serverTimestamp(),
				annualSalary: annualSalary,
				status: decision && decision,
				// only set the status to Approved if the status is Approved
				// ...(decision === "Approved" && { status: "Approved" }),
			});
		} catch (error) {
			setError(error.message);
		}
	};

	const saveDeclineAssessment = async (declineReason = null) => {
		try {
			const user_res = await setDoc(doc(db, 'assessedUPL', id), {
				...loanDeets,
				...result,
				...finalExpenses,
				...finalRates,
				surchargeRate: surcharge.surchargeRate,
				surcharge: surcharge.surcharge,
				decline_uid: user.uid,
				declinedBy: user.displayName,
				dateDeclined: serverTimestamp(),
				status: 'Declined',
				declineReason: declineReason && declineReason,
			});
		} catch (error) {
			setError(error.message);
		}
	};

	const updateAssessor = async () => {
		// update the assessor with user.displayname and uid
		// set the status to Assessing
		try {
			console.log('hasAssessor==>>>', hasAssessor);

			if (!appID) return;

			setIsLoading(true);

			if (status === 'Submitted') {
				console.log('hasAssessor==>>>', hasAssessor);

				const docRef = doc(db, 'unsecuredLoanApplications', appID);
				const res = await updateDoc(docRef, {
					assessor: {
						uid: user.uid,
						displayName: user.displayName,
					},
					dateAssessed: serverTimestamp(),
					status: 'Assessing',
				});

				// setAppStatus("Assessing");
				notifyUser('You are now the Assessor of this application');
			}

			setHasAssessor(true);
			setIsSubmitted(false);
			setIsAssessing(true);
			// reset
			setIsLoading(false);
		} catch (error) {
			setError(error.message);
			setIsLoading(false);
		}
	};

	const updateDeclineStatus = async (_reason, _declineOtherReason = null) => {
		try {
			const docRef = doc(db, 'unsecuredLoanApplications', id);
			const res = await updateDoc(docRef, {
				dateDeclined: serverTimestamp(),
				declineReason: _reason,
				declineOtherReason: _declineOtherReason,
				decline_uid: user.uid,
				declinedBy: user.displayName,
				status: 'Declined',
			});
			setAppStatus('Declined');

			//notify the user
			notifyUser(DECLINE_NTFCTN);
		} catch (error) {
			setError(error.message);
		}
	};

	useEffect(() => {
		const controller = new AbortController();
		const { signal } = controller;

		const r = roles.roles;

		if (r) {
			if (r.includes('approver')) {
				setIsApprover(true);
			}
			if (r.includes('assessor')) {
				setIsAssessor(true);
			}
			if (r.includes('compliance')) {
				setIsCompliance(true);
			}
			if (r.includes('escalator')) {
				setIsEscalator(true);
			}
		}

		if (status === 'Submitted') {
			setIsSubmitted(true);
			if (r.includes('assessor')) {
				updateAssessor();
			}
		}
		if (status === 'Assessing') {
			setIsAssessing(true);
		}
		if (status === 'Recommended') {
			setIsRecommended(true);
		}
		if (status === 'Approved') {
			setIsApproved(true);

			// saveAssessment();
		}
		if (status === 'Escalated') {
			setIsEscalated(true);
		}
		if (status === 'Escalated') {
			setIsEscalated(true);
		}

		// abort data request
		return () => {
			controller.abort();
		};
	}, [roles]);

	return (
		<Grid container flex gap={2} justifyContent={'flex-end'} sx={{ mt: 3 }}>
			<div>
				{isLoading && <p>Loading data...</p>}
				{/*  ACTION BUTTONS ========================================================================================*/}
				{/* action buttons */}
				{isAssessing && isAssessor && (
					<Grid item xs={12}>
						{/* ========================================================================================*/}
						{/* Recommend */}
						<Button
							variant='contained'
							type='button'
							onClick={handleOpenRecommend}
							sx={{
								minWidth: '100px',
								backgroundColor: '#002a48',
								textTransform: 'capitalize',
								borderRadius: '25px',
							}}
							disabled={isSending}
						>
							Recommend
						</Button>

						{/* ========================================================================================*/}
						{/* Decline */}
						<Button
							variant='contained'
							type='button'
							onClick={handleOpen}
							style={{ marginLeft: '0.5em', marginRight: '0.5em' }}
							sx={{
								minWidth: '100px',
								backgroundColor: '#002a48',
								textTransform: 'capitalize',
								borderRadius: '25px',
							}}
						>
							Decline
						</Button>
					</Grid>
				)}
				{/* ========================================================================================*/}
				{/* Finalise */}
				{/* add isEscalator test OR */}
				{isRecommended && isApprover && (
					<Grid item xs={12}>
						{/* Approve */}
						<Button
							variant='contained'
							type='button'
							onClick={handleOpenApprove}
							sx={{
								minWidth: '100px',
								backgroundColor: '#002a48',
								textTransform: 'capitalize',
								borderRadius: '25px',
							}}
							disabled={isSending}
						>
							Finalise
						</Button>
					</Grid>
				)}
				{/* isEscalated */}
				{isEscalated && isEscalator && (
					<Grid item xs={12}>
						{/* Approve */}
						<Button
							variant='contained'
							type='button'
							onClick={handleOpenApprove}
							sx={{
								minWidth: '100px',
								backgroundColor: '#002a48',
								textTransform: 'capitalize',
								borderRadius: '25px',
							}}
							disabled={isSending}
						>
							Finalise
						</Button>
					</Grid>
				)}

				{/* Approved - All for saving of Assessment*/}
				{/* {isApproved && (isCompliance || isAssessor) && (
          <Button
            variant="contained"
            type="button"
            onClick={saveAssessment}
            style={{ marginLeft: "0.5em", marginRight: "0.5em" }}
            sx={{
              minWidth: "100px",
              backgroundColor: "#002a48",
              textTransform: "capitalize",
              borderRadius: "25px",
            }}
          >
            Save Assessment
          </Button>
        )} */}

				{/* DIALOGUES ========================================================================================*/}
				{/* decline application dialogue */}
				<Decline
					open={open}
					handleClose={handleClose}
					sendMsg={sendMsg}
					firstName={firstName}
					loanAmount={loanAmount}
					dateApplied={dateApplied}
					appStatus={appStatus}
					setAppStatus={setAppStatus}
					updateDeclineStatus={updateDeclineStatus}
					saveDeclineAssessment={saveDeclineAssessment}
				/>
				{/* ========================================================================================*/}
				{/* recommend application dialogue */}
				<Grid item xs={12}>
					<Dialog
						open={openRecommend}
						onClose={handleCloseRecommend}
						aria-labelledby='alert-dialog-title'
						aria-describedby='alert-dialog-description'
					>
						<DialogTitle id='alert-dialog-title'>
							{'Recommend Application'}
						</DialogTitle>
						<DialogContent>
							<DialogContentText id='alert-dialog-description'>
								Recommend the following application for approval
							</DialogContentText>
							<textarea
								wïdth='100%'
								name='recommendationNotes'
								value={recommendation}
								onChange={(e) => setRecommendation(e.target.value)}
								maxLength='3000'
								rows='8'
								cols='50'
							/>
						</DialogContent>
						<DialogActions>
							<Button
								type='button'
								variant='contained'
								onClick={handleRecommendApplication}
								autoFocus
								sx={{
									minWidth: '100px',
									backgroundColor: '#002a48',
									textTransform: 'capitalize',
									borderRadius: '25px',
								}}
							>
								Recommend
							</Button>
						</DialogActions>
					</Dialog>
				</Grid>
				{/* ========================================================================================*/}
				{/* approve application dialogue */}
				<Approve
					open={openApprove}
					handleClose={handleCloseApprove}
					sendMsg={sendMsg}
					firstName={firstName}
					lastName={lastName}
					otherNames={otherNames}
					loanAmount={loanDeets.loanAmount}
					originalLoanAmount={loanAmount}
					dateApplied={dateApplied}
					updateFinaliseApproveStatus={updateFinaliseApproveStatus}
					updateFinaliseDeclineStatus={updateFinaliseDeclineStatus}
					updateFinaliseEscalateStatus={updateFinaliseEscalateStatus}
					saveAssessment={saveAssessment}
					isEscalated={isEscalated}
				/>
				<ToastContainer />
			</div>
			{error && <Error err={error} module='Assessment' />}
		</Grid>
	);
};

export default Actions;
