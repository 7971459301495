import React, { useState, useEffect } from 'react';

export const useSendMail = () => {
	const [error, setError] = useState(null);
	const [isPending, setIsPending] = useState(false);
	const [isCancelled, setIsCancelled] = useState(false);

	const signature =
		'Kind Regards,<br />' +
		'Unsecured Personal Loan Team<br />' +
		'Email: upl@mfl.com.fj | Phone: 3314955<br />' +
		'Merchant Finance Limited<br />' +
		'Level 1, Ramarama House<br />' +
		'91 Gordon Street, Suva';

	// const ccEmail1 = "upl@mfl.com.fj";
	// const ccEmail2 = "uplteam@mfl.com.fj";
	// const ccEmail3 = "creditsubmissions@mfl.com.fj";

	const ccEmail1 = 'ravisa.daunivuka@mfl.com.fj';
	const ccEmail2 = 'ravisa.daunivuka@gmail.com';
	const ccEmail3 = 'ravisa@ascend.com.fj';

	const sendMsg = async (
		toEmail,
		subject,
		htmlMessage,
		textMessage,
		userEmail = null,
		status = null
	) => {
		let msg = {
			to: toEmail,
			// cc: [ccEmail1, ccEmail2],
			nickname: 'Merchant Finance Loans',
			from: { email: 'loan@mfl.com.fj', name: 'Merchant Finance Loans' },
			subject: subject,
			text: textMessage + '<br /><br />' + signature,
			html: htmlMessage + '<br /><br />' + signature,
		};

		// test for recommended status
		if (status === 'recommend') {
			msg = { ...msg, cc: [ccEmail1, ccEmail2, ccEmail3] };
			console.log('recommended email sent', msg);
		} else {
			msg = { ...msg, cc: [ccEmail1, ccEmail2] };
		}

		// send the message
		try {
			const response = await fetch(
				'https://australia-southeast1-loansportal-b4e57.cloudfunctions.net/sendEmail',
				{
					method: 'POST',
					body: JSON.stringify(msg),
					cache: 'no-cache', // I added
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);
			// set the state
			if (!isCancelled) {
				setIsPending(false);
				setError(null);
			}
		} catch (err) {
			// set the state
			if (!isCancelled) {
				setIsPending(false);
				setError(err.message);
			}
		}
	};

	useEffect(() => {
		return () => setIsCancelled(true);
	}, []);

	return { error, isPending, sendMsg };
};

export default useSendMail;
