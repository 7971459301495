import React from 'react';
import DocInfo from './DocInfo';
import { format } from 'date-fns';
import styles from './PreAssessment.module.css';

const SupportingDocs = ({ data }) => {
	const toTitleCase = (str) => {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};

	let USDollar = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});

	return (
		<div
			style={{
				textAlign: 'left',
				padding: '0.5rem',
				margin: '0.5rem',
				marginTop: '0.9rem',
				fontSize: '0.9rem',
				// border: '1px solid #f2f2f2',
				// borderRadius: '5px',
				// height: '100%',
				minWidth: '100%',
			}}
			className={styles.cardShadow}
		>
			{/* personal details ---------------------------------------------------------------------------------- */}
			<h4 style={{ margin: 0, padding: 0 }}>Personal Details</h4>
			<p style={{ margin: 0, padding: 0 }}>
				{toTitleCase(data.firstName.trim())} {toTitleCase(data.lastName.trim())}
			</p>
			<p style={{ margin: 0, padding: 0 }}>
				{' '}
				{format(new Date(data.dateOfBirth), 'dd MMM yyyy')}
			</p>
			<p style={{ margin: 0, padding: 0 }}>
				{toTitleCase(data.maritalStatus.trim())}
			</p>
			<p style={{ margin: 0, padding: 0 }}>{data.residentialAddress1}</p>
			<p style={{ margin: 0, padding: 0 }}>{data.durationOfStay1}</p>
			<p style={{ margin: 0, padding: 0 }}>{data.mobile}</p>
			{/* <p style={{ margin: 0, padding: 0 }}>{data.loanPurpose}</p> */}

			{/* work details ---------------------------------------------------------------------------------- */}
			<h4 style={{ margin: 0, padding: 0, marginTop: '1rem' }}>Work Details</h4>
			<p style={{ margin: 0, padding: 0 }}>{data.employer}</p>
			<p style={{ margin: 0, padding: 0 }}>{data.durationOfEmployment1}</p>
			<p style={{ margin: 0, padding: 0 }}>
				{toTitleCase(data.occupation.trim())}
			</p>
			<p style={{ margin: 0, padding: 0 }}>
				{USDollar.format(data.annualSalary)}
			</p>
			<p style={{ margin: 0, padding: 0 }}>{data.employerAddress}</p>

			{/* documents ---------------------------------------------------------------------------------- */}
			<h4 style={{ margin: 0, padding: 0, marginTop: '1rem' }}>
				Supporting Docs
			</h4>

			{/* pay slips */}
			{data.payslips &&
				data.payslips.map((doc, index) => (
					<React.Fragment key={index}>
						<DocInfo docTitle='Pay Slips' docRef={doc} index={index} />
					</React.Fragment>
				))}

			{/* bank statements */}
			{data.bankStatement &&
				data.bankStatement.map((doc, index) => (
					<React.Fragment key={index}>
						<DocInfo docTitle='Bank Statements' docRef={doc} index={index} />
					</React.Fragment>
				))}

			{/* other financial statements */}
			{data.otherStatements &&
				data.otherStatements.map((doc, index) => (
					<React.Fragment key={index}>
						<DocInfo docTitle='Other Statements' docRef={doc} index={index} />
					</React.Fragment>
				))}

			{/* employment confirmation */}
			{data.employmentConfirmation &&
				data.employmentConfirmation.map((doc, index) => (
					<React.Fragment key={index}>
						<DocInfo docTitle='Work Confirmation' docRef={doc} index={index} />
					</React.Fragment>
				))}

			{/* tin card / letter */}
			{data.tinLetterCard &&
				data.tinLetterCard.map((doc, index) => (
					<React.Fragment key={index}>
						<DocInfo docTitle='Tin Letter/Card' docRef={doc} index={index} />
					</React.Fragment>
				))}

			{/* photo ids */}
			{data.photoIDs &&
				data.photoIDs.map((doc, index) => (
					<React.Fragment key={index}>
						<DocInfo docTitle='Photo IDs' docRef={doc} index={index} />
					</React.Fragment>
				))}
		</div>
	);
};

export default SupportingDocs;
