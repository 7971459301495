import { Button } from "@mui/material";
import { useFormikContext } from "formik";

const SubmitButton = ({ children, ...otherProps }) => {
  const { submitForm } = useFormikContext();

  const handleSubmit = () => {
    submitForm();
  };

  const configButton = {
    ...otherProps,
    variant: "contained",
    onClick: handleSubmit,
    size: "small",
  };

  return (
    <Button
      sx={{
        minWidth: "150px",
        backgroundColor: "#002a48",
        textTransform: "capitalize",
        borderRadius: "25px",
        padding: ".5rem",
        fontSize: "1rem",
      }}
      {...configButton}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
