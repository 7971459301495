import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { format } from 'date-fns';

import { useSendMail } from '../hooks/useSendMail';

//firebase imports
import { db } from '../firebase/config';
import {
	collection,
	addDoc,
	updateDoc,
	doc,
	serverTimestamp,
} from 'firebase/firestore';

import {
	getStorage,
	ref,
	uploadBytesResumable,
	getDownloadURL,
} from 'firebase/storage';

import ActionButton from './FormUI/ActionButton';
import styles from './Submission.module.css';
// import TextError from "./FormUI/TextError";
import Error from '../components/error/Error';
import { Link } from 'react-router-dom';

import ArrowCircleLeftTwoToneIcon from '@mui/icons-material/ArrowCircleLeftTwoTone';

const UPL_COLLECTION = 'unsecuredLoanApplications';

const Submission = ({ prev, data }) => {
	const [uploadProgress, setUploadProgress] = useState(0);
	const [fileBeingUploaded, setFileBeingUploaded] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [inProgress, setInProgress] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const storage = getStorage();
	const [error, setError] = useState(null);

	const { sendMsg } = useSendMail();

	// set the urls for each file
	const [sig, setSig] = useState([]);
	const [pid, setPID] = useState([]);
	const [empCon, setEmpCon] = useState([]);
	const [tin, setTin] = useState([]);
	const [ps, setPs] = useState([]);
	const [bs, setBs] = useState([]);
	const [os, setOs] = useState([]);

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, []);

	const saveApplication = async () => {
		setIsLoading(true);
		setInProgress(true);
		let formData = { ...data };
		// formData = data;

		try {
			// set fields for saving
			formData.dateApplied = serverTimestamp();
			formData.dateCreated = serverTimestamp();
			data.appliedAt = serverTimestamp();
			data.dateApplied = serverTimestamp();
			data.createdAt = serverTimestamp();
			data.status = 'Submitted';
			data.appStatus = 'Open';

			// set up the files
			let signature = data.signature;
			let photoIDs = data.photoIDs;
			let employmentConfirmation = data.employmentConfirmation;
			let tinLetterCard = data.tinLetterCard;
			let payslips = data.payslips;
			let bankStatement = data.bankStatement;
			let otherStatements = data.otherStatements;

			// reset file refernces from formData
			data.signature = [];
			data.photoIDs = [];
			data.employmentConfirmation = [];
			data.tinLetterCard = [];
			data.payslips = [];
			data.bankStatement = [];
			data.otherStatements = [];

			// add the document
			const res = await addDoc(collection(db, UPL_COLLECTION), {
				...data,
			});

			// const r1 = await handleFileUpload(res.id, signature, "sig");
			await handleFileInputUpload(formData, res.id);
			await sendMessage(formData);

			// application addition and upload complete
			setInProgress(false);
			setIsComplete(true);
		} catch (err) {
			setError(err.message);
			// setIsLoading(false);
			setInProgress(false);
			setIsComplete(true);
		}
		// setIsLoading(false);
		setInProgress(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		saveApplication();
	};

	const handleFileInputUpload = async (formData, appID, data) => {
		let urls = [];
		let files = formData.signature;
		let folderName = 'signature';

		try {
			// upload signature
			for (const file of files) {
				// const storageRef = ref(storage, `files/${file.name}`);
				const storageRef = ref(
					storage,
					`reqs/upl/${appID}/${folderName}/${file.name}`
				);
				const uploadTask = uploadBytesResumable(storageRef, file);

				setFileBeingUploaded(file.name);

				uploadTask.on('state_changed', (snapshot) => {
					// Handle upload progress
					const progress =
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					setUploadProgress(Number(progress).toFixed(0));
				});

				await uploadTask;

				const downloadUrl = await getDownloadURL(storageRef);
				urls.push(downloadUrl);

				// Update the download URL array in Firestore
				const docRef = doc(db, UPL_COLLECTION, appID);
				const updateRes = await updateDoc(docRef, {
					signature: urls,
				});
			}

			// handle photoIDs
			urls = [];
			files = formData.photoIDs;
			folderName = 'photoIDs';

			// upload photoIDs
			for (const file of files) {
				// const storageRef = ref(storage, `files/${file.name}`);
				const storageRef = ref(
					storage,
					`reqs/upl/${appID}/${folderName}/${file.name}`
				);
				const uploadTask = uploadBytesResumable(storageRef, file);

				setFileBeingUploaded(file.name);

				uploadTask.on('state_changed', (snapshot) => {
					// Handle upload progress
					const progress =
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					setUploadProgress(Number(progress).toFixed(0));
				});

				await uploadTask;

				const downloadUrl = await getDownloadURL(storageRef);
				urls.push(downloadUrl);

				// Update the download URL array in Firestore
				const docRef = doc(db, UPL_COLLECTION, appID);
				const updateRes = await updateDoc(docRef, {
					photoIDs: urls,
				});
			}

			// handle employmentConfirmation
			urls = [];
			files = formData.employmentConfirmation;
			folderName = 'employmentConfirmation';

			// upload employmentConfirmation
			for (const file of files) {
				// const storageRef = ref(storage, `files/${file.name}`);
				const storageRef = ref(
					storage,
					`reqs/upl/${appID}/${folderName}/${file.name}`
				);
				const uploadTask = uploadBytesResumable(storageRef, file);

				setFileBeingUploaded(file.name);

				uploadTask.on('state_changed', (snapshot) => {
					// Handle upload progress
					const progress =
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					setUploadProgress(Number(progress).toFixed(0));
				});

				await uploadTask;

				const downloadUrl = await getDownloadURL(storageRef);
				urls.push(downloadUrl);

				// Update the download URL array in Firestore
				const docRef = doc(db, UPL_COLLECTION, appID);
				const updateRes = await updateDoc(docRef, {
					employmentConfirmation: urls,
				});
			}

			// handle tinLetterCard
			urls = [];
			files = formData.tinLetterCard;
			folderName = 'tinLetterCard';

			// upload tinLetterCard
			for (const file of files) {
				// const storageRef = ref(storage, `files/${file.name}`);
				const storageRef = ref(
					storage,
					`reqs/upl/${appID}/${folderName}/${file.name}`
				);
				const uploadTask = uploadBytesResumable(storageRef, file);

				setFileBeingUploaded(file.name);

				uploadTask.on('state_changed', (snapshot) => {
					// Handle upload progress
					const progress =
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					setUploadProgress(Number(progress).toFixed(0));
				});

				await uploadTask;

				const downloadUrl = await getDownloadURL(storageRef);
				urls.push(downloadUrl);

				// Update the download URL array in Firestore
				const docRef = doc(db, UPL_COLLECTION, appID);
				const updateRes = await updateDoc(docRef, {
					tinLetterCard: urls,
				});
			}

			// handle payslips
			urls = [];
			files = formData.payslips;
			folderName = 'payslips';

			// upload payslips
			for (const file of files) {
				// const storageRef = ref(storage, `files/${file.name}`);
				const storageRef = ref(
					storage,
					`reqs/upl/${appID}/${folderName}/${file.name}`
				);
				const uploadTask = uploadBytesResumable(storageRef, file);

				setFileBeingUploaded(file.name);

				uploadTask.on('state_changed', (snapshot) => {
					// Handle upload progress
					const progress =
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					setUploadProgress(Number(progress).toFixed(0));
				});

				await uploadTask;

				const downloadUrl = await getDownloadURL(storageRef);
				urls.push(downloadUrl);

				// Update the download URL array in Firestore
				const docRef = doc(db, UPL_COLLECTION, appID);
				const updateRes = await updateDoc(docRef, {
					payslips: urls,
				});
			}

			// handle bankStatement
			urls = [];
			files = formData.bankStatement;
			folderName = 'bankStatement';

			// upload bankStatement
			for (const file of files) {
				// const storageRef = ref(storage, `files/${file.name}`);
				const storageRef = ref(
					storage,
					`reqs/upl/${appID}/${folderName}/${file.name}`
				);
				const uploadTask = uploadBytesResumable(storageRef, file);

				setFileBeingUploaded(file.name);

				uploadTask.on('state_changed', (snapshot) => {
					// Handle upload progress
					const progress =
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					setUploadProgress(Number(progress).toFixed(0));
				});

				await uploadTask;

				const downloadUrl = await getDownloadURL(storageRef);
				urls.push(downloadUrl);

				// Update the download URL array in Firestore
				const docRef = doc(db, UPL_COLLECTION, appID);
				const updateRes = await updateDoc(docRef, {
					bankStatement: urls,
				});
			}

			// otherStatements
			// handle otherStatements
			urls = [];
			files = formData.otherStatements;
			folderName = 'otherStatements';

			// upload otherStatements
			for (const file of files) {
				// const storageRef = ref(storage, `files/${file.name}`);
				const storageRef = ref(
					storage,
					`reqs/upl/${appID}/${folderName}/${file.name}`
				);
				const uploadTask = uploadBytesResumable(storageRef, file);

				setFileBeingUploaded(file.name);

				uploadTask.on('state_changed', (snapshot) => {
					// Handle upload progress
					const progress =
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					setUploadProgress(Number(progress).toFixed(0));
				});

				await uploadTask;

				const downloadUrl = await getDownloadURL(storageRef);
				urls.push(downloadUrl);

				// Update the download URL array in Firestore
				const docRef = doc(db, UPL_COLLECTION, appID);
				const updateRes = await updateDoc(docRef, {
					otherStatements: urls,
				});
			}
		} catch (error) {
			console.log('File Upload', error.message);
		}
	};

	const sendMessage = async (props) => {
		const {
			email,
			firstName,
			lastName,
			otherNames,
			loanAmount,
			loanPurpose,
			appliedAt,
		} = props;

		const portalLink = `https://loans.mfl.com.fj`;
		const _toEmail = email;
		const _subject = 'Unsecured Personal Loan Application Confirmation';
		const _htmlMessage =
			'Bula ' +
			firstName +
			',<br /><br />' +
			'This is to confirm that we have received your application.<br /><br />' +
			'Customer Name: ' +
			firstName +
			' ' +
			otherNames +
			' ' +
			lastName +
			'<br />Loan Amount: ' +
			'$' +
			loanAmount +
			'<br />Date Applied: ' +
			format(new Date(), 'EEE dd/MM/yyyy hh:mm a') +
			'<br />Loan Purpose: ' +
			loanPurpose +
			'<br /><br />' +
			'You can check the progress of your loan application at any time on our <a href=' +
			portalLink +
			' >Loans Portal</a><br />' +
			'We will be processing your application before the end of the next business day i.e. 24 hours<br />' +
			'Thank you for giving us the opportunity to be your financial services provider of choice.';

		const _textMessage =
			'Bula ' +
			firstName +
			',<br />' +
			'This is to confirm that we have received your application.<br /><br />' +
			'Customer Name: ' +
			firstName +
			' ' +
			otherNames +
			' ' +
			lastName +
			'- Loan Amount: ' +
			'$' +
			loanAmount +
			'- Loan Purpose: ' +
			loanPurpose +
			'- Date Applied: ' +
			format(new Date(), 'EEE dd/MM/yyyy hh:mm a');

		try {
			await sendMsg(_toEmail, _subject, _htmlMessage, _textMessage);
		} catch (err) {
			setError(err.message);
		}
	};

	return (
		<div className={styles.container}>
			<h1>Submission</h1>
			<Grid container display={'flex'} justifyContent='center'>
				<form onSubmit={handleSubmit}>
					{!isComplete ? (
						<Grid item xs={12}>
							<p>
								You are about to submit your application.
								<br />
								Please note that this may take a minute or two for files need to
								be uploaded.
								<br />
								Thank you for your patience.
							</p>
							<div
								style={{
									color: 'red',
									border: 'none',
									padding: '1rem',
									borderRadius: '5px',
									background: '#fac9c8',
								}}
							>
								<p>
									<strong>
										After you press the Submit Application button, <br />
										DO NOT CLOSE this screen until this notice disappears!
									</strong>
								</p>
							</div>
						</Grid>
					) : null}
					{inProgress ? (
						<Grid
							item
							xs={12}
							sx={{ display: 'flex', flexDirection: 'column' }}
						>
							<div>
								{fileBeingUploaded} {uploadProgress}
								{'% done'}
							</div>
							<div>
								<progress value={uploadProgress} max='100' />
							</div>
						</Grid>
					) : null}

					{/* completion notice */}
					{isComplete ? (
						<Grid
							item
							xs={12}
							sx={{ display: 'flex', flexDirection: 'column' }}
						>
							<p>
								You application has been successfully submitted!
								<br />A confirmation has also been sent to your email address{' '}
								{data.email}
								<br />
								Updates on your application will be sent by our loans team to
								your email - {data.email}
								<br />
								You can also check your loan status on our{' '}
								{/* <a href="www.loans.mfl.com.fj" alt="MFL Loans Portal">
                  Loans Portal
                </a> */}
								<Link to='/' replace>
									Loans Portal
								</Link>
								<br />
								Vinaka Vakalevu
							</p>
						</Grid>
					) : null}

					{/* button */}
					{!isComplete ? (
						<Grid container spacing={2}>
							<Grid
								item
								xs={12}
								style={{
									display: 'flex',
									justifyContent: 'center',
									marginTop: '1rem',
									width: '100%',
									columnGap: '10px',
								}}
							>
								<ActionButton
									onClick={() => {
										prev();
									}}
									// style={{ color: "#1976d2" }}
								>
									<ArrowCircleLeftTwoToneIcon
										sx={{
											fontSize: '1.75rem !important',
										}}
									/>
									&nbsp;Back
								</ActionButton>
								<Button
									variant='contained'
									disabled={isLoading}
									type='submit'
									// onClick={handleSubmit}
									sx={{
										minWidth: '100px',
										backgroundColor: '#002a48',
										textTransform: 'capitalize',
										borderRadius: '25px',
									}}
								>
									Submit Application
								</Button>

								{error ? <Error err={error} module='Submission' /> : null}

								{/* <button type="submit" disabled={isLoading}>
                Submit Application
              </button> */}
							</Grid>
						</Grid>
					) : null}
				</form>
			</Grid>
			{error && <Error err={error} module='Submission' />}
		</div>
	);
};

export default Submission;
