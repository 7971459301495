import { useState, useEffect, useRef } from "react";

//firebase imports
import { db } from "../firebase/config";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";

export const useCollection = (
  c,
  _q = null,
  _o = null,
  _l = null,
  _q2 = null
) => {
  const [documents, setDocuments] = useState(null);

  //setup query
  const q = useRef(_q).current;

  useEffect(() => {
    let ref = collection(db, c);

    if (q) {
      if (_q2) {
        ref = query(ref, where(...q), where(..._q2));
      } else {
        ref = query(ref, where(...q));
      }
    }

    if (_o) {
      ref = query(ref, orderBy(_o));
    }

    if (_l) {
      ref = query(ref, limit(_l));
    }

    const unSub = onSnapshot(ref, (snapshot) => {
      let results = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setDocuments(results);
    });

    return () => unSub();
  }, [c, q]);

  return { documents };
};
