import React from 'react';

const DocInfo = ({ docTitle, docRef, index }) => {
	return (
		<div>
			{/* {index === 0 && <h2>{docTitle}</h2>} */}
			<a
				href={docRef}
				alt={`${docTitle} ${index + 1}`}
				target='_blank'
				rel='noopener noreferrer'
				style={{ fontSize: '.9rem' }}
			>
				{docTitle} {index + 1}
			</a>
		</div>
	);
};

export default DocInfo;
