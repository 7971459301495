import React, { useState, useEffect } from 'react';

// firebase imports
import { db } from '../../firebase/config';
import {
	getDocs,
	collection,
	orderBy,
	query,
	limit,
	getCountFromServer,
	startAfter,
	endBefore,
	where,
	limitToLast,
} from 'firebase/firestore';

import ListApplications from './ListApplications';
import Filter from '../search/Filter';

const UPL_COLLECTION = 'unsecuredLoanApplications';

const Applications = () => {
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [lastDoc, setLastDoc] = useState(null);
	const [firstDoc, setFirstDoc] = useState(null);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(15);
	const [totalCount, setTotalCount] = useState(0);
	const [currentFilter, setCurrentFilter] = useState('Submitted');

	const loadNextData = async () => {
		try {
			let q = null;
			const uplRef = collection(db, UPL_COLLECTION);

			if (lastDoc) {
				if (currentFilter === 'All') {
					q = query(
						uplRef,
						orderBy('dateApplied', 'desc'),
						orderBy('firstName', 'asc'),
						startAfter(lastDoc),
						limit(pageSize)
					);
				} else {
					q = query(
						uplRef,
						where('status', '==', currentFilter),
						orderBy('dateApplied', 'desc'),
						orderBy('firstName', 'asc'),
						startAfter(lastDoc),
						limit(pageSize)
					);
				}
			} else {
				if (currentFilter === 'All') {
					q = query(
						uplRef,
						orderBy('dateApplied', 'desc'),
						orderBy('firstName', 'asc'),
						limit(pageSize)
					);
				} else {
					q = query(
						uplRef,
						where('status', '==', currentFilter),
						orderBy('dateApplied', 'desc'),
						orderBy('firstName', 'asc'),
						limit(pageSize)
					);
				}
			}

			// execute the query
			const res = await getDocs(q);
			const resData = res.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));

			// set the last doc
			setLastDoc(res.docs[res.docs.length - 1]);

			// set the first doc of the page
			setFirstDoc(res.docs[0]);

			// set the data
			setData(resData);
			setIsLoading(false);
		} catch (error) {
			console.log('Error occured fetching data:', error.message);
			setError(error.message);
			setIsLoading(false);
		}
	};

	const loadPreviousData = async () => {
		try {
			const uplRef = collection(db, UPL_COLLECTION);

			if (lastDoc) {
				let q = null;

				if (currentFilter === 'All') {
					q = query(
						uplRef,
						orderBy('dateApplied', 'desc'),
						orderBy('firstName', 'asc'),
						endBefore(firstDoc),
						limitToLast(pageSize)
					);
				} else {
					q = query(
						uplRef,
						where('status', '==', currentFilter),
						orderBy('dateApplied', 'desc'),
						orderBy('firstName', 'asc'),
						endBefore(firstDoc),
						limitToLast(pageSize)
					);
				}
				// execute the query
				const res = await getDocs(q);
				const resData = res.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));

				// set the last doc
				setLastDoc(res.docs[res.docs.length - 1]);
				// set the first doc of the page
				setFirstDoc(res.docs[0]);

				// set the data
				setData(resData);
			}
			// }
			setIsLoading(false);
		} catch (error) {
			console.log('Error loading prev data:', error.message);
			setError(error.message);
			setIsLoading(false);
		}
	};

	const getCount = async () => {
		setIsLoading(true);
		try {
			if (currentFilter === 'All') {
				// get the count
				const res = await getCountFromServer(collection(db, UPL_COLLECTION));
				setTotalCount(res.data().count);
			} else {
				const uplRef = collection(db, UPL_COLLECTION);
				const q = query(uplRef, where('status', '==', currentFilter));
				// execute the query
				const res = await getDocs(q);
				setTotalCount(res.docs.length);
			}

			setIsLoading(false);
		} catch (error) {
			console.log('Error occured getting count:', error.message);
			setError(error.message);
			setIsLoading(false);
		}
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);

		// get data for next or prev
		if (newPage > page) {
			loadNextData(lastDoc);
		} else {
			loadPreviousData();
		}
	};

	const changeFilter = (newFilter) => {
		setLastDoc(null);
		setCurrentFilter(newFilter);
	};

	useEffect(() => {
		const controller = new AbortController();

		setPage(0);
		getCount();
		loadNextData();

		// abort data request
		return () => {
			controller.abort();
		};
	}, [pageSize, currentFilter]);

	return (
		<div
			style={{
				backgroundColor: '#fff',
				borderRadius: '5px',
				padding: '1rem',
			}}
		>
			<h1>UPL Applications</h1>
			<Filter
				currentFilter={currentFilter}
				changeFilter={changeFilter}
				isLoading={isLoading}
			/>
			<ListApplications
				data={data}
				page={page}
				pageSize={pageSize}
				isLoading={isLoading}
				totalCount={totalCount}
				handleChangePage={handleChangePage}
				setLastDoc={setLastDoc}
			/>
			{error && <p>{error}</p>}
		</div>
	);
};

export default Applications;
